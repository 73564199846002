import React, { useState, useEffect, lazy } from 'react'
import { Formik } from 'formik'
import {
  Chip,
  Switch,
  Typography,
  MenuItem,
  Select,
  Button
} from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { useUserStore } from 'store/GlobalContext'
import { getStudentCourse } from 'services/api/student'
import { getErrorMessage, IsEmpty } from 'services/helpers'
import { ERROR } from 'services/constants'
import TAG_MANAGER from 'tagManager'
import {
  isValidDisabled,
  statusInitially,
  defaultPage,
  ERROR_MESSAGE
} from './constants'
import * as Styled from './style'

const CardCourseDetail = lazy(() => import('../CardCourseDetail'))

const FormDiscipline = ({
  statusList,
  setType,
  setCourseGrouping,
  switchValue,
  setSwitchValue,
  shouldFilters,
  shouldFilterStatus = true,
  setButtonVisible,
  buttonVisible,
  setPage,
  setSnack,
  updateSnack,
  isDesktop
}) => {
  const { username: id } = useUserStore()
  const [infoClass, setInfoClass] = useState([])
  const [courseGrouping, setcourseGrouping] = useState('')
  const [courseId, setCourseId] = useState('')

  const getCourseDetail = () => {
    const course = infoClass.find((item) => item.id === courseId)
    return course || infoClass[0]
  }

  const courseItem = getCourseDetail()

  const getSetCourse = (idSelected) => {
    const [schoolClassId] = infoClass.filter((item) => item.id === idSelected)
    setCourseId(schoolClassId?.id)
  }

  const onHandleSelectCourse = (e, setFieldValue) => {
    const { value } = e.target
    getSetCourse(value)
    setFieldValue('course', value)
    setcourseGrouping(value)
    setType(statusInitially)
  }

  const onHandleSelectStatus = (e, setFieldValue) => {
    const { value } = e.target
    setFieldValue('status', value)
    setType(value)
    setPage(defaultPage)
  }

  const onHandleSwitch = () => {
    setSwitchValue(!switchValue)
  }

  const onStudents = async () => {
    updateSnack({})
    const { data: response, error, status } = await getStudentCourse({ id })
    if (response) {
      if (!courseId && !IsEmpty(response?.data))
        setCourseId(response?.data[0].id)
      setInfoClass(response?.data)
      setCourseGrouping(courseItem || response?.data[0])
    }

    if (error) {
      const message = getErrorMessage(ERROR_MESSAGE, status)
      setSnack(message, ERROR)
    }
  }

  useEffect(() => {
    onStudents()
  }, [courseGrouping])

  const initialState = {
    status: 'Em andamento',
    course: courseItem?.id
  }

  return (
    <>
      <Styled.Header>
        <Styled.Course>
          <Styled.TitleCourse variant="h6">Curso Técnico:</Styled.TitleCourse>

          {shouldFilters && (
            <Formik initialValues={initialState} enableReinitialize>
              {({ values, setFieldValue }) => (
                <Styled.BoxForm>
                  <Select
                    className={TAG_MANAGER.student_select_change_course}
                    labelId="select-course_id"
                    name="course"
                    value={values?.course ?? ''}
                    onChange={(e) => onHandleSelectCourse(e, setFieldValue)}
                    fullWidth
                    width="100%"
                    disabled={infoClass.length <= isValidDisabled}
                  >
                    {infoClass.map((item) => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.name} ({item.course_id})
                      </MenuItem>
                    ))}
                  </Select>
                </Styled.BoxForm>
              )}
            </Formik>
          )}
        </Styled.Course>

        {IsEmpty(courseItem?.school_classes) && (
          <Styled.BoxInfo>
            <Chip label="Sem turma" color="info" />
          </Styled.BoxInfo>
        )}
        {!isDesktop && (
          <>
            <Styled.BoxFilter>
              <Button
                variant="outlined"
                startIcon={<FilterAltIcon />}
                onClick={() => setButtonVisible(!buttonVisible)}
              >
                Filtrar
              </Button>
            </Styled.BoxFilter>
            <CardCourseDetail data={courseItem} />
          </>
        )}
      </Styled.Header>

      <Styled.DividerHeader />

      {shouldFilters && shouldFilterStatus && (
        <Styled.Card>
          <Formik initialValues={initialState} enableReinitialize>
            {({ values, setFieldValue }) => (
              <Styled.Status>
                <Styled.BoxForm>
                  <Select
                    className={
                      TAG_MANAGER.student_select_filters_situation_Discipline
                    }
                    labelId="select-status"
                    name="status"
                    value={values?.status ?? ''}
                    onChange={(e) => onHandleSelectStatus(e, setFieldValue)}
                    fullWidth
                    width="100%"
                  >
                    {statusList.map((item) => (
                      <MenuItem key={item.value} value={item.label}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Styled.BoxForm>

                <Styled.Switch>
                  <Switch
                    className={
                      TAG_MANAGER.student_switch_filters_Failed_courses
                    }
                    checked={switchValue}
                    onClick={onHandleSwitch}
                  />
                  <Typography>Reprovados</Typography>
                </Styled.Switch>
              </Styled.Status>
            )}
          </Formik>
        </Styled.Card>
      )}
    </>
  )
}

export default FormDiscipline
