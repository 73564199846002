import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from 'store/GlobalContext'

import Grid from '@mui/material/Unstable_Grid2'
import { Button, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useEffect, useState } from 'react'
import { listUnitInfo } from 'services/api/student/unitInfo'
import { TabView } from 'components/organisms'
import { Header, Container, Body, HeaderButton } from './style'

const UnitInfo = () => {
  const navigate = useNavigate()
  const userStore = useUserStore()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  const titleStyle = { fontWeight: 700, fontSize: { xs: '12px', md: '14px' } }
  const infoStyle = { fontWeight: 300, fontSize: { xs: '12px', md: '14px' } }

  const [unitInfo, setUnitInfo] = useState([])
  const [tabs, setTabs] = useState([])

  const tabsMaker = (infos) => {
    const tabsBuff = []
    infos.forEach((info, index) => {
      tabsBuff.push({
        label: info.unitname,
        value: `tab${index}`,
        component: () => (
          <>
            <Grid container xs={12} spacing={2}>
              {info.maps && (
                <Grid
                  xs={12}
                  md={6}
                  sx={{
                    height: { xs: '250px', md: '500px' }
                  }}
                >
                  <iframe
                    src={info.maps}
                    title="map"
                    width="100%"
                    height="100%"
                    style={{ border: 0, borderRadius: '16px' }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </Grid>
              )}
              <Grid xs={12} md={6}>
                <Typography variant="body1" sx={titleStyle}>
                  Endereço:
                </Typography>
                <Typography variant="body1" sx={infoStyle}>
                  {info.adress}, {info.number} - {info.neighborhood},{' '}
                  {info.city} - {info.uf}, {info.areacode}
                </Typography>
                <br />
                <Typography variant="body1" sx={titleStyle}>
                  Telefone:
                </Typography>
                <Typography variant="body1" sx={infoStyle}>
                  {info.fone}
                </Typography>
                <br />
                <Typography variant="body1" sx={titleStyle}>
                  Diretor:
                </Typography>
                <Typography variant="body1" sx={infoStyle}>
                  {info.director}
                </Typography>
                <br />
                <Typography variant="body1" sx={titleStyle}>
                  Secretaria:
                </Typography>
                <Typography variant="body1" sx={infoStyle}>
                  {info.secretary}
                </Typography>
              </Grid>
            </Grid>
          </>
        )
      })
    })

    setTabs(tabsBuff)
  }

  const getStudentUnitsInfo = async () => {
    const { data: units } = await listUnitInfo(userStore)
    setUnitInfo(units.data)
    tabsMaker(units.data)
  }

  useEffect(() => {
    getStudentUnitsInfo()
  }, [])

  return (
    <>
      <Container>
        <Body>
          <Grid container spacing={2}>
            <Grid xs={1}>
              <HeaderButton>
                <Button
                  sx={{ color: 'black' }}
                  onClick={() => {
                    navigate('../student/hub')
                  }}
                >
                  <ArrowBackIcon />
                </Button>
              </HeaderButton>
            </Grid>
            <Grid xs={11}>
              <Header>
                <Typography
                  variant="h4"
                  sx={{ fontSize: { xs: '20px', md: '32px' } }}
                >
                  Minha Unidade
                </Typography>
              </Header>
            </Grid>
            <Grid xs={12}>{!!tabs.length && <TabView tabs={tabs} />}</Grid>
          </Grid>
        </Body>
      </Container>
    </>
  )
}

export default UnitInfo
